import React from 'react';
import styled from 'styled-components';
import {BREAKPOINTS} from '../theme';
import {LinkIcon} from './Icon';
import LinkedInIcon from "../assets/icons/linkedin.icon";
import GitHubIcon from "../assets/icons/github.icon";

const StyledContact = styled.div`
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: ${BREAKPOINTS.sm}) {
        margin: 10px 0;
    }
`;

const Contact = () =>
    <StyledContact>
        <LinkIcon
            title='LinkedIn'
            link='https://www.linkedin.com/in/julie-nginn/'
            icon={<LinkedInIcon/>}
            size='S'
        />
        <LinkIcon
            title='GitHub'
            link='https://github.com/Madylune'
            icon={<GitHubIcon/>}
            size='S'
        />
    </StyledContact>;

export default Contact;
