import React from 'react';
import styled from 'styled-components';
import {getContextColor} from '../utils';

const StyledRibbon = styled.div`
    width: 200px;
    padding: 13px;
    position: absolute;
    top: 22px;
    right: ${props => !props.left ? '-75px' : undefined};
    left: ${props => props.left ? '-75px' : undefined};
    text-align: center;
    transform: ${props => props.left ? 'rotate(-45deg)' : 'rotate(45deg)'};
    background-color: ${props => getContextColor(props.context)};
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    color: #000000;
`;

const Ribbon = ({context, left = false}: { context: 'Game Jam' | 'Pro' | 'Perso' | 'Scolaire'; left?: boolean }) =>
    <StyledRibbon context={context} left={left}>{context}</StyledRibbon>;

export default Ribbon;