import React from 'react';
import styled from 'styled-components';
import {PROJECTS} from '../fixtures';
import {BREAKPOINTS} from '../theme';
import ProjectCard from '../components/ProjectCard';
import Title from '../components/Title';


const StyledProjects = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;

    @media (max-width: ${BREAKPOINTS.sm}) {
        flex-direction: column;
        align-items: center;
    }
`;

const StyledSubtitle = styled.h3`
    font-size: 20px;
    text-align: center;
    color: #949494;

    @media (max-width: ${BREAKPOINTS.sm}) {
        font-size: 1em;
    }
`;

const StyledHr = styled.hr`
    width: 60%;
`;

const Projects = ({repos}) => {
  return (
    <div id="projects">
      <Title title="Mes projets"/>
      <StyledHr/>
      <StyledSubtitle>Jeux</StyledSubtitle>
      <StyledProjects>
        {PROJECTS.map(project => project.type === 'game' && (
          <ProjectCard key={project.title} repos={repos} project={project}/>
        ))}
      </StyledProjects>
      <StyledHr/>
      <StyledSubtitle>Web</StyledSubtitle>
      <StyledProjects>
        {PROJECTS.map(project => project.type === 'web' && (
          <ProjectCard key={project.title} repos={repos} project={project}/>
        ))}
      </StyledProjects>
    </div>
  );
};

export default Projects;