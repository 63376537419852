import styled from 'styled-components';
import {BREAKPOINTS} from "../theme";

export const StyledCard = styled.div`
    width: 345px;
    margin: 10px;
    background-color: #ffffff;
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.5);
`;

export const StyledBlogCard = styled.div`
    position: relative;
    width: 300px;
    height: 300px;
    margin: 10px;
    background-color: #ffffff;
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.5);

    @media (max-width: ${BREAKPOINTS.sm}) {
        width: 90%;
        margin: 10px auto;
    }
`;

export const BlogCardMedia = styled.div`
    height: 50%;
    overflow: hidden;
    background-color: #ffffff;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.5);

    img {
        width: 100%;
    }
`;

export const CardMedia = styled.div`
    height: 180px;
    overflow: hidden;

    img {
        width: 100%;
    }
`;

export const CardContent = styled.div`
    padding: 8px;
    font-size: 12px;
`;

export const CardActions = styled.div`
    padding: 5px 5px 10px;
`;

export const BlogCardActions = styled.div`
    bottom: 10px;
    left: 10px;
    position: absolute;
`;

export const Button = styled.a`
    padding: 3px 9px;
    border: 1px solid #ccc;
    color: #212121;
    border-radius: 4px;
    text-decoration: none;

    @media (max-width: ${BREAKPOINTS.sm}) {
        font-size: 8px;
    }
`;

export const StyledCardWithRibbon = styled.div`
    position: relative;
    margin: 20px;
    width: 350px;
    height: 450px;
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.5);
    overflow: hidden;

    @media (max-width: ${BREAKPOINTS.sm}) {
        margin: 20px 0;
        width: 90%;
    }
`;

export const StyledTags = styled.ul`
    display: flex;
    flex-direction: row;
    list-style-type: none;
    padding: 0;
    margin-top: 20px;

    li {
        margin-right: 10px;
    }
`;