import {useParams} from "react-router-dom";
import {POST_LINKS, POSTS} from "../blog/blog.constants";
import React, {useEffect, useState} from "react";
import {useHelmetHook} from "../hooks/useHelmet.hook";
import {
    Background,
    Date,
    StyledBanner,
    StyledBlogPost,
    StyledContent,
    StyledTags,
    TagButton
} from "../blog/blog.styled";
import {LinkMenu} from "../components/Link";
import Markdown from "markdown-to-jsx";

const ArticlePage = () => {
    const params = useParams();
    const currentPost = POSTS.find(post => post.url === params.postId);
    const [markdown, setMarkdown] = useState("");
    
    useHelmetHook({
        title: `Julie Nginn | Blog | ${currentPost.title}`,
        description: currentPost.description,
        keywords: currentPost.keywords
    });
    
    useEffect(() => {
        if (POSTS && params && params.postId) {
            fetch(currentPost.file.toString())
              .then((res) => res.text())
              .then((text) => setMarkdown(text))
              .catch((err) => console.error(err));
        }
    }, [currentPost, params]);
    
    return (
      <StyledBlogPost>
          <StyledBanner height={100}/>
          <LinkMenu links={POST_LINKS}/>
          {markdown.length
            ? <StyledContent>
                <Background>
                    <img alt="" src={currentPost.image}/>
                </Background>
                <Markdown options={{wrapper: 'article'}}>{markdown}</Markdown>
                <Date>Publié le {currentPost.date}.</Date>
                <hr/>
                <StyledTags>
                    {currentPost.tags.map(tag => <TagButton key={tag}>#{tag}</TagButton>)}
                </StyledTags>
            </StyledContent>
            : <StyledContent>Contenu indisponible, réessayez plus tard 🕦</StyledContent>
          }
      </StyledBlogPost>
    );
};

export default ArticlePage;