import {useEffect} from "react";

export type HelmetHookProps = {
    title: string;
    keywords: string;
    description: string;
}

export const useHelmetHook = ({title, description, keywords}: HelmetHookProps) => {
    useEffect(() => {
        document.title = title;
        document.getElementsByTagName('meta')['description'].content = description;
        document.getElementsByTagName('meta')['keywords'].content = keywords;
    }, [description, keywords, title]);
};
