import React from 'react';
import styled from 'styled-components';
import {BREAKPOINTS} from '../theme';

const TimelineSeparator = styled.div`
    flex: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 40px;
    margin: 0 10px;

    @media (max-width: ${BREAKPOINTS.sm}) {
        display: none;
    }
`;

const TimelineConnector = styled.span`
    width: 2px;
    flex-grow: 1;
    background-color: #bdbdbd;
`;

const StyledTimeline = styled.ul`
    padding: 0;
    margin-top: 0;
`;

const StyledTimelineItem = styled.li`
    display: flex;
    justify-content: center;
    margin: 7px 0;

    @media (
        max-width: ${BREAKPOINTS.sm}) {
        flex-direction: column;
        align-items: center;
    }
`;

const TimelineContent = styled.div`
    width: 50%;

    @media (max-width: ${BREAKPOINTS.sm}) {
        width: 100%;
    }
`;

const StyledPaper = styled.div`
    background-color: #ffffff;
    color: #000000;
    border-radius: 10px;
    padding: 10px 15px;
    width: 70%;

    @media (max-width: ${BREAKPOINTS.md}) {
        width: 90%;
    }
    @media (max-width: ${BREAKPOINTS.sm}) {
        margin: auto;
        padding: 5px;
        width: 95%;
    }
`;

const StyledDate = styled.h3`
    font-size: 15px;

    @media (max-width: ${BREAKPOINTS.md}) {
        font-size: 10px;
    }
`;

const StyledTitle = styled.h3`
    font-size: 16px;
    margin: 10px 0;

    @media (max-width: ${BREAKPOINTS.md}) {
        font-size: 10px;
    }
`;

const StyledDescription = styled.p`
    font-size: 13px;
    margin: 5px 0 10px;

    @media (max-width: ${BREAKPOINTS.md}) {
        font-size: 8px;
    }
`;

const StyledTimelineDot = styled.span`
    background-color: ${({pictoColor}) => pictoColor};
    border-radius: 50%;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 50px;
    margin-bottom: 5px;

    @media (max-width: ${BREAKPOINTS.sm}) {
        margin-left: 15px;
    }

    svg {
        font-size: 24px;
    }
`;

const StyledTimelineOppositeContent = styled.div`
    width: 50%;
    text-align: right;

    @media (max-width: ${BREAKPOINTS.md}) {
        width: 30%;
    }

    @media (max-width: ${BREAKPOINTS.sm}) {
        width: 100%;
        font-size: 8px;
        text-align: center;
    }
`;

const Button = styled.a`
    padding: 3px 9px;
    border: 1px solid ${({pictoColor}) => pictoColor};
    border-radius: 4px;
    text-decoration: none;
    color: ${({pictoColor}) => pictoColor};

    @media (max-width: ${BREAKPOINTS.sm}) {
        font-size: 8px;
    }
`;

type ItemProps = {
    when: string;
    where: string;
    what: string;
    url?: string;
}

type TimelineProps = {
    items: ItemProps[];
    pictoColor: string;
    picto: any;
}

export default function TimelineComponent({items, pictoColor, picto}: TimelineProps) {
    return (
        <StyledTimeline>
            {items.map(item => (
                <StyledTimelineItem key={item.what}>
                    <StyledTimelineOppositeContent>
                        <StyledDate>{item.when}</StyledDate>
                    </StyledTimelineOppositeContent>
                    <TimelineSeparator>
                        <StyledTimelineDot pictoColor={pictoColor}>{picto}</StyledTimelineDot>
                        <TimelineConnector/>
                    </TimelineSeparator>
                    <TimelineContent>
                        <StyledPaper>
                            <StyledTitle>{item.where}</StyledTitle>
                            <StyledDescription>
                                {item.what}
                            </StyledDescription>
                            {item.url && (
                                <Button pictoColor={pictoColor} href={item.url} target="_blank"
                                        rel="noopener noreferrer" aria-label={item.what}>
                                    Aller voir
                                </Button>
                            )}
                        </StyledPaper>
                    </TimelineContent>
                </StyledTimelineItem>
            ))}
        </StyledTimeline>
    );
}
