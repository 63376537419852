import styled from "styled-components";
import {BREAKPOINTS} from "../theme";

export const StyledTitle = styled.h1`
    color: #383838;
    font-size: 40px;

    @media (max-width: ${BREAKPOINTS.sm}) {
        font-size: 20px;
    }
`;

export const StyledBlogPost = styled.div`
    background-color: #D9D9D9;
    min-height: 100vh;
    margin: 0;
`;

export const StyledBanner = styled.header`
    background: url(${require("../assets/blog_banner.jpg")}) no-repeat;
    background-position-y: center;
    background-size: cover;
    height: ${({height}) => `${height}px`};
`;

export const StyledContent = styled.div`
    width: 60%;
    margin: auto;
    padding: 15px 60px;
    background-color: #ffffff;

    @media (max-width: ${BREAKPOINTS.md}) {
        width: 85%;
        font-size: 11px;
    }

    @media (max-width: ${BREAKPOINTS.sm}) {
        width: 100%;
        padding: 0;
        margin: 0;
        font-size: 10px;

        article {
            padding: 0 15px;
        }
    }

    .postImage {
        margin: 10px auto;
        height: auto;
        max-width: 100%;


        @media (max-width: ${BREAKPOINTS.sm}) {
            width: 80%;
            height: auto;
        }
    }
`;

export const StyledNav = styled.nav`
    margin-top: 50px;
`;

export const StyledCards = styled.ul`
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;

    @media (max-width: ${BREAKPOINTS.sm}) {
        flex-direction: column;
    }
`;

export const StyledImage = styled.img`
    margin: auto;
    height: 200px;
`;

export const StyledTags = styled.ul`
    display: flex;
    flex-direction: row;
    list-style-type: none;
    padding: 0;
    margin-top: 20px;
`;

export const TagButton = styled.li`
    padding: 3px 9px;
    border: 1px solid #ccc;
    color: #212121;
    border-radius: 4px;
    margin-right: 20px;

    @media (max-width: ${BREAKPOINTS.sm}) {
        font-size: 8px;
    }
`;

export const Date = styled.p`
    text-align: right;
    font-size: 12px;

    @media (max-width: ${BREAKPOINTS.sm}) {
        font-size: 10px;
    }
`;

export const Background = styled.div`
    max-height: 450px;
    overflow: hidden;
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.5);
    margin-bottom: 30px;

    img {
        width: 100%;
    }

    @media (max-width: ${BREAKPOINTS.md}) {
        height: 300px;
    }
`;