import React from 'react';
import styled from 'styled-components';
import {BREAKPOINTS} from '../theme';
import {LinkMenu} from "../components/Link";
import ScrollAnchor from "../components/ScrollAnchor";
import {getNavigationLinks} from "../utils";

const StyledHome = styled.header`
    background: url(${require('../assets/home-background.png')}) no-repeat;
    background-size: contain;
    background-position: right;
    background-color: #D9D9D9;

    position: relative;
    height: 100vh;

    @media (max-width: ${BREAKPOINTS.sm}) {
        margin: 0;
        height: auto;
        background: none;
    }
`;

const StyledTitle = styled.div`
    background-color: #323232;
    border: 5px solid #000000;
    border-radius: 10px;
    padding: 20px;
    position: absolute;
    top: 25%;
    left: 20px;
    width: 50%;

    @media (max-width: ${BREAKPOINTS.sm}) {
        position: initial;
        background-color: #D9D9D9;
        padding: 5px 10px;
        height: auto;
        width: auto;
        top: 15px;
        left: 0;
        border: none;
        border-radius: 0;
    }
`;

const StyledName = styled.h1`
    color: #e3e3e3;
    margin: 10px 0 30px;
    font-size: 50px;

    @media (max-width: ${BREAKPOINTS.sm}) {
        color: #383838;
        margin: 10px 0;
        font-size: 20px;
    }
`;

const StyledSubTitle = styled.h2`
    font-size: 30px;
    color: #e3e3e3;

    @media (max-width: ${BREAKPOINTS.sm}) {
        color: #383838;
        font-size: 18px;
    }
`;

const StyledDescription = styled.p`
    font-size: 20px;
    color: #e3e3e3;

    @media (max-width: ${BREAKPOINTS.sm}) {
        color: #383838;
        font-size: 11px;
    }
`;

const MenuWrapper = styled.nav`
    position: absolute;
    top: 10px;
    left: 0;


    @media (max-width: ${BREAKPOINTS.sm}) {
        position: initial;
        top: 0;
    }
`;

const NAVIGATION_MENU = getNavigationLinks(['projects', 'talks', 'blog']);

const Home = () =>
  <StyledHome id="accueil">
    <MenuWrapper>
      <LinkMenu links={NAVIGATION_MENU}/>
    </MenuWrapper>
    <StyledTitle>
      <StyledName>Julie Nginn</StyledName>
      <StyledSubTitle>Software Developer</StyledSubTitle>
      <StyledDescription>
        L'accessibilité est au coeur de mes préoccupations, à défaut de pouvoir rendre le monde accessible à tous, je
        peux au moins essayer de le faire pour nos applications.
      </StyledDescription>
    </StyledTitle>
    <ScrollAnchor/>
  </StyledHome>;

export default Home;