// icon:033-books | Icomoon https://icomoon.io/ | Keyamoon
import * as React from "react";

function ArticleIcon(props) {
  return (
    <svg
      viewBox="0 0 18 16"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path
        fill="currentColor"
        d="M3.5 2h-3c-.275 0-.5.225-.5.5v11c0 .275.225.5.5.5h3c.275 0 .5-.225.5-.5v-11c0-.275-.225-.5-.5-.5zM3 5H1V4h2v1zM8.5 2h-3c-.275 0-.5.225-.5.5v11c0 .275.225.5.5.5h3c.275 0 .5-.225.5-.5v-11c0-.275-.225-.5-.5-.5zM8 5H6V4h2v1z"
      />
      <path
        fill="currentColor"
        d="M11.954 2.773l-2.679 1.35a.502.502 0 00-.222.671l4.5 8.93a.502.502 0 00.671.222l2.679-1.35a.502.502 0 00.222-.671l-4.5-8.93a.502.502 0 00-.671-.222z"
      />
      <path fill="currentColor" d="M14.5 13.5a.5.5 0 11-1 0 .5.5 0 011 0z"/>
    </svg>
  );
}

export default ArticleIcon;
