import React from 'react';
import styled from 'styled-components';
import {HOBBIES} from '../fixtures';
import Title from '../components/Title';
import {Card} from '../components/Cards';

const StyledWrapper = styled.div`
    padding: 10px;
    background-color: #bdbdbd;
    color: #555555;
`;

const StyledCardsWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`;

const Hobbies = () =>
  <StyledWrapper id="hobbies">
    <Title title="Mes passions"/>
    <StyledCardsWrapper>
      {HOBBIES.map(hobby => (
        <Card key={hobby.title} {...hobby} />
      ))}
    </StyledCardsWrapper>
  </StyledWrapper>;

export default Hobbies;