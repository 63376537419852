import moment from 'moment';
import 'moment/locale/fr';
import {LINKS} from "./fixtures";

export const getLastUpdate = (repos: any[], githubID: number) => {
    const repository = repos.find(repo => repo.id === githubID);
    const timestamp = repository && repository.pushed_at;
    const date = timestamp && new Date(timestamp);
    moment.locale('fr');

    return date && moment(date).startOf('hour').fromNow();
};

export const getContextColor = (context: string) => {
    switch (context) {
        case 'Perso':
            return '#65ffb2';
        case 'Pro':
            return '#7acaff';
        case 'Scolaire':
            return '#ffbd7b';
        case 'Game Jam':
            return '#dc97ff';
        default:
            return '#999';
    }
};

export const getNavigationLinks = (ids: string[]) => ids.map(id => LINKS.find(link => link.id === id));
