import React from 'react';
import styled from 'styled-components';
import {BREAKPOINTS} from '../theme';

type IconSize = 'S' | 'M' | 'L';

type IconProps = {
    title: string;
    link: string;
    icon: any;
    size?: IconSize;
}

const getSize = (size: IconSize) => {
    if (size === 'M') {
        return {
            default: 50,
            lg: 40
        }
    }

    if (size === 'L') {
        return {
            default: 55,
            lg: 45
        }
    }

    return {
        default: 42,
        lg: 32
    }
}

const StyledIcon = styled.div`
    svg {
        margin: 10px;
        font-size: ${({size}) => getSize(size).default}px;
        color: #ffffff;

        &:hover {
            animation: rotate-center 0.5s ease-in-out both;

            @keyframes rotate-center {
                0% {
                    transform: rotate(0);
                }
                100% {
                    transform: rotate(360deg);
                }
            }
        }

        @media (max-width: ${BREAKPOINTS.lg}) {
            font-size: ${({size}) => getSize(size).lg}px;
        }
        @media (max-width: ${BREAKPOINTS.sm}) {
            &:hover {
                animation: none;
            }
        }
    }
`;

const StyledLink = styled.a`
    text-decoration: none;
`;

export const LinkIcon = ({icon, link, title, size}: IconProps) =>
    <StyledLink href={link} target="_blank" rel="noopener noreferrer" aria-label={title}>
        <StyledIcon size={size}>
            {icon}
        </StyledIcon>
    </StyledLink>
