import React from 'react';
import {Link} from "react-router-dom";
import styled from "styled-components";
import {BREAKPOINTS} from "../theme";

const LinkMenuWrapper = styled.ul`
    display: flex;
    list-style-type: none;
    padding: 0;

    @media (max-width: ${BREAKPOINTS.sm}) {
        justify-content: center;
    }
`;

const LinkItem = styled.li`
    margin: 0 20px;
    font-size: ${({size}) => `${size === 'S' ? 13 : 18}px`};
    text-transform: uppercase;

    a {
        text-decoration: none;
        color: #000000;
    }

    &:hover {
        text-decoration: underline;
    }

    @media (max-width: ${BREAKPOINTS.sm}) {
        font-size: 11px;
    }
`;

type LinkProps = {
    url: string;
    label: string;
    icon: any;
    id: string;
}


const LinkComponent = ({url, label, icon}: LinkProps) => <Link to={url}>{icon} {label}</Link>;

const LinkMenu = ({links, size}: { links: LinkProps[], size?: 'S' | undefined }) => {
    return (
        <nav>
            <LinkMenuWrapper>
                {links.map((link) => (
                    <LinkItem key={link.id} size={size}>
                        <LinkComponent {...link}/>
                    </LinkItem>
                ))}
            </LinkMenuWrapper>
        </nav>
    );
};

export {LinkComponent, LinkMenu};