import React from 'react';
import styled from 'styled-components';
import {getLastUpdate} from '../utils';
import {CardWithRibbon} from "./Cards";


const StyledCardHeader = styled.div`
    padding: 5px 15px;
    font-size: 11px;
`;

const CardTitle = styled.h4`
    font-size: 15px;
    font-weight: 400;
`;

const StyledCardMedia = styled.div`
    height: 150px;
    width: 100%;
    overflow: hidden;

    img {
        width: 100%;
    }
`;

const CardContent = styled.div`
    padding: 16px;
`;

const StyledDescription = styled.p`
    font-size: 12px;
`;

type ProjectProps = {
    title: string;
    play: string;
    picture: string;
    description: string;
    type: 'game' | 'web';
    published: string;
    context: 'Game Jam' | 'Pro' | 'Perso' | 'Scolaire';
    githubID?: number;
}

const ProjectCard = ({repos, project}: { repos: any; project: ProjectProps }) =>
    <CardWithRibbon context={project.context}>
        <StyledCardHeader>
            <CardTitle>{project.title}</CardTitle>
            <p>
                {project.githubID ? `Mis à jour ${getLastUpdate(repos, project.githubID)}` : `Publié en ${project.published}`}
            </p>
        </StyledCardHeader>
        <StyledCardMedia>
            <img src={require(`../assets/projects/${project.picture}`)} alt={project.title}/>
        </StyledCardMedia>
        <CardContent>
            <StyledDescription>
                <span dangerouslySetInnerHTML={{__html: project.description}}/>
            </StyledDescription>
        </CardContent>
    </CardWithRibbon>

export default ProjectCard;