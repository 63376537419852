// icon:microphone-2 | Tabler Icons https://tablericons.com/ | Csaba Kissi
import * as React from "react";

function MicIcon(props) {
    return (
        <svg
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            {...props}
        >
            <path stroke="none" d="M0 0h24v24H0z" />
            <path d="M15.002 12.9A5 5 0 1011.1 9M15.002 12.9L11.1 9.001l-7.513 8.584a2 2 0 102.827 2.83l8.588-7.515z" />
        </svg>
    );
}

export default MicIcon;
