import React from "react";
import Ribbon from "./Ribbon";
import {
    StyledCard,
    StyledBlogCard,
    BlogCardMedia,
    CardMedia,
    CardContent,
    CardActions,
    BlogCardActions,
    Button,
    StyledCardWithRibbon,
    StyledTags
} from './Cards.styled';

type CardProps = {
    title: string;
    image: string;
    description?: string;
    url?: string;
    button?: string;
    targetBlank?: boolean
}

export const Card = ({title, image, description, url = undefined, button = undefined, targetBlank = true}: CardProps) =>
  <StyledCard>
      <CardMedia>
          <img alt="" src={image}/>
      </CardMedia>
      <CardContent>
          <h3>{title}</h3>
          <p>{description}</p>
      </CardContent>
      {button && url && (
        <CardActions>
            <Button href={url} target={targetBlank ? "_blank" : undefined} rel="noopener noreferrer">
                {button}
            </Button>
        </CardActions>
      )}
  </StyledCard>;


type CardWithRibbonProps = {
    context: 'Game Jam' | 'Pro' | 'Perso' | 'Scolaire';
    children: React.ReactNode;
}

export const CardWithRibbon = ({context, children}: CardWithRibbonProps) =>
  <StyledCardWithRibbon>
      <Ribbon context={context}/>
      {children}
  </StyledCardWithRibbon>;


type BlogCardProps = CardProps & {
    tags?: string[];
}

export const BlogCard = ({
                             title,
                             image,
                             tags = [],
                             url = undefined,
                             button = undefined,
                             targetBlank = true
                         }: BlogCardProps) =>
  <StyledBlogCard>
      <BlogCardMedia>
          <img alt={title} src={image}/>
      </BlogCardMedia>
      <CardContent>
          <h3>{title}</h3>
          <StyledTags>{tags.map(tag => <li key={tag}>#{tag}</li>)}</StyledTags>
      </CardContent>
      {button && url && (
        <BlogCardActions>
            <Button href={url} target={targetBlank ? "_blank" : undefined} rel="noopener noreferrer">
                {button}
            </Button>
        </BlogCardActions>
      )}
  </StyledBlogCard>;
