import React from "react";
import {createBrowserRouter} from "react-router-dom";
import HomePage from "./pages/home.page";
import BlogPage from './pages/blog.page';
import ArticlePage from './pages/article.page';

export const router = createBrowserRouter([
    {
        path: "/",
        element:
          <HomePage
            title={'Julie Nginn'}
            description={'Portfolio de Julie Nginn, passionnée par la conception d\'applications, de sites web et de jeux vidéo.'}
            keywords={'Dev, Développement, Developer, React, JavaScript, Accessibilité, Accessibility, FrontEnd'}
          />
    },
    {
        path: "blog",
        element:
          <BlogPage
            title={'Julie Nginn | Blog'}
            description={'Blog de Julie Nginn | Je partage ici mes idées, mes expériences, mes découvertes...'}
            keywords={'Dev, Développement, Developer, React, JavaScript, Accessibilité, Accessibility, FrontEnd'}
          />
    },
    {
        path: "blog/:postId",
        element: <ArticlePage/>,
    },
]);
