const BREAKPOINT_XS: number = 0
const BREAKPOINT_SM: number = 48
const BREAKPOINT_MD: number = 64
const BREAKPOINT_LG: number = 75

export const BREAKPOINTS = {
    xs: `${BREAKPOINT_XS}em`,
    sm: `${BREAKPOINT_SM}em`,
    md: `${BREAKPOINT_MD}em`,
    lg: `${BREAKPOINT_LG}em`
}