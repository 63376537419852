import React from 'react';
import {LinkMenu} from "../components/Link";
import {BlogCard} from "../components/Cards";
import {
    StyledTitle,
    StyledBlogPost,
    StyledBanner,
    StyledContent,
    StyledNav,
    StyledCards,
    StyledImage,
} from "../blog/blog.styled";
import {POSTS, POST_FILTERS, BLOG_LINKS} from "../blog/blog.constants";
import {HelmetHookProps, useHelmetHook} from "../hooks/useHelmet.hook";

const BlogPage = ({title, description, keywords}: HelmetHookProps) => {
    useHelmetHook({title, description, keywords});
    
    return (
      <StyledBlogPost>
          <StyledBanner height={250}/>
          <LinkMenu links={BLOG_LINKS}/>
          <StyledContent>
              <StyledTitle>Bienvenue sur mon blog !👋🏻</StyledTitle>
              <p>Je partage ici mes idées, mes expériences, mes découvertes...</p>
              <StyledNav>
                  <LinkMenu links={POST_FILTERS} size="S"/>
              </StyledNav>
              <hr/>
              <StyledCards>
                  {!POSTS.length &&
										<StyledImage src={require('../assets/coming-soon.png')} alt="Bientôt disponible"/>}
                  {POSTS.length >= 1 && POSTS.map((post) =>
                    <li key={post.id}>
                        <BlogCard
                          title={post.title}
                          image={post.image}
                          url={`blog/${post.url}`}
                          button="Lire l'article"
                          targetBlank={false}
                          tags={post.tags}
                        />
                    </li>
                  )}
              </StyledCards>
          </StyledContent>
      </StyledBlogPost>
    );
};

export default BlogPage;