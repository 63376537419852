import React from "react";
import {getNavigationLinks} from "../utils";
import TableIcon from "../assets/icons/table.icon";

export const POST_LINKS = getNavigationLinks(['portfolio', 'blog']);
export const BLOG_LINKS = getNavigationLinks(['portfolio']);

export const POST_FILTERS = [
    {
        id: 'all',
        icon: <TableIcon/>,
        label: 'Tous les articles',
        url: '',
    },
];

export const POSTS = [
    {
        id: 'blog_post_1',
        title: "CRASH TEST: WAVE, accessibility tool",
        description: "J'ai testé Wave, un outil qui permet d'évaluer l'accessibilité d'une application développé par WebAIM.",
        image: 'https://wave.webaim.org/img/wavelogo.svg',
        file: require("./test_wave.md"),
        tags: ['crash-test', 'accessibilité', 'développement'],
        date: '22/12/2024',
        url: 'crash-test-wave-accessibility-tool',
        keywords: 'blog, audit, évaluation, wave, webAIM, accessibility tool, accessibility, crash-test, accessibilité, développement, test, refactoring, performance'
    },
    // {
    //     id: 'blog_post_2',
    //     title: "Comment avoir une application TDAH-friendly ?",
    //     description: "Voici quelques conseils pour rendre votre application accessible pour les personnes qui ont un TDAH.",
    //     image: 'https://i.postimg.cc/TYf844zM/tdah-app.jpg',
    //     file: require("./adhd_friendly_apps.md"),
    //     tags: ['accessibilité', 'développement'],
    //     date: '23/12/2024',
    //     url: 'comment-avoir-une-application-tdah-friendly',
    //     keywords: "blog, apps, application, tdah, hyperactivité, trouble de l'attention, développement, accessibility, accessibilité, app, handicap"
    //
    // }
];
