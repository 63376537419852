import React, {useEffect, useState} from 'react'
import axios from 'axios'
import styled from 'styled-components'
import Home from '../blocks/Home'
import About from '../blocks/About'
import Projects from '../blocks/Projects'
import Hobbies from '../blocks/Hobbies'
import Footer from '../blocks/Footer'
import {HelmetHookProps, useHelmetHook} from "../hooks/useHelmet.hook";

const StyledApp = styled.div`
    height: 100vh;
    margin: 0;
`

const HomePage = ({title, description, keywords}: HelmetHookProps) => {
    const [repos, setRepos] = useState<any[]>([]);
    useHelmetHook({title, description, keywords});
    
    useEffect(() => {
        axios.get(`https://api.github.com/users/Madylune/repos`).then(resp => setRepos(resp.data))
    }, []);
    
    return (
      <StyledApp>
          <Home/>
          <About/>
          <Projects repos={repos}/>
          <Hobbies/>
          <Footer/>
      </StyledApp>
    )
}

export default HomePage;
